import React from "react";
import { Switch, Route } from "react-router-dom";

import SampleUpload from "./SampleUpload/SampleUpload";

export default function Routes({ match }) {
  return (
    <Switch>
      <Route path="/p/test/:token" exact component={SampleUpload} />
    </Switch>
  );
}
