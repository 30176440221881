import React, { useEffect, Fragment } from "react";
import { Switch, Route, NavLink } from "react-router-dom";

import Api from "app/js/api";
import { useSafeState } from "app/js/hooks";

import Header from "app/components/Header/Header";
import Submenu from "app/components/Menus/Submenu/Submenu";
import Loading from "app/components/Loading/Loading";
import ResourceDoesNotExist from "app/components/Error/ResourceDoesNotExist";
import { LinkPaid } from "app/componentConstraints";

import StreamDetail from "./StreamDetail";
import StreamActionscript from "./StreamActionscript";
import StreamLog from "./StreamLog";
import StreamImages from "./StreamImages";
import { useUserStore } from "app/js/stores";

import styles from "./StreamDetail.scss";

export default function Routes({ match, history }) {
  const [user, _userActions] = useUserStore();
  const streamId = match.params.id;
  const [stream, setStream] = useSafeState(null);
  const [loading, setLoading] = useSafeState(false);
  const [error, setError] = useSafeState("");

  const loadStream = React.useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await Api.stream(streamId).show();
      setStream(response.data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [setError, setLoading, setStream, streamId]);
  useEffect(() => {
    loadStream();
  }, [loadStream, user.data]);

  if (error != null) {
    return <ResourceDoesNotExist resourceRoot={"/labelling-jobs"} />;
  }

  return (
    <div className={styles.container}>
      <Header basePath={match.url} showCreate={false} showEdit={true}>
        {stream && <h1>Camera {stream.name}</h1>}
        {loading && <h1>Loading...</h1>}
      </Header>
      <Submenu>
        <li>
          <NavLink
            exact={true}
            to={`/streams/${streamId}`}
            activeClassName="active"
          >
            Overview
          </NavLink>
        </li>
        <li>
          <NavLink
            exact={true}
            to={`/streams/${streamId}/images`}
            activeClassName="active"
          >
            Images
          </NavLink>
        </li>
        <LinkPaid to={`/streams/${streamId}/script`} activeClassName="active">
          Actionscript
        </LinkPaid>
        {
          <li>
            <NavLink to={`/streams/${streamId}/log`} activeClassName="active">
              Log
            </NavLink>
          </li>
        }
        <li style={{ marginLeft: "auto" }}>
          <button
            onClick={() => {
              if (confirm("Are you sure?")) {
                Api.stream(streamId)
                  .destroy()
                  .then((response) => {
                    history.push("/streams");
                  });
              }
            }}
          >
            Delete
          </button>
        </li>
      </Submenu>
      <div className={styles.content}>
        <Switch>
          {
            <Route
              path={`${match.path}/images`}
              exact
              component={StreamImages}
            />
          }
          {<Route path={`${match.path}/log`} exact component={StreamLog} />}
          <Route
            path={`${match.path}/script`}
            exact
            render={() => (
              <Fragment>
                {loading && <Loading />}
                {!loading && stream && <StreamActionscript stream={stream} />}
              </Fragment>
            )}
          />
          <Route
            path={match.path}
            render={() => (
              <Fragment>
                {loading && <Loading />}
                {!loading && stream && (
                  <StreamDetail
                    stream={stream}
                    loadStream={loadStream}
                    match={match}
                  />
                )}
              </Fragment>
            )}
          />
        </Switch>
      </div>
    </div>
  );
}
